import request from "@/utils/request";

let url = "https://api.hangzhouluofeng.com:8081"
// let url = "http://192.168.50.103:8080"

export function push(data) {
    return request({
        url: url + '/api/data/push',
        method: 'get',
        params: data
    })
}

export function subscribe(data) {
    return request({
        url: url + '/api/user/subscribe',
        method: 'post',
        data: data
    })
}
