<template>
  <!--  <nav-bar/>-->
  <div class="body">
    <img src="../assets/lt1.jpg" style="height: 100%;width: 100%;"/>
    <div class="detail">
      <div class="input">
        <input @focus="focus" v-model="input" placeholder="输入您的联通手机号">
      </div>
      <div class="btn">
        <div class="text" @click="btnClick">点击领取福利</div>
        <div class="position">领完为止</div>
        <!--        <button>点击领取福利</button>-->
      </div>
      <div class="checkbox">
        <input type="checkbox" v-model="option" name="option" value="value">
        <div>我已经阅读并同意
          <div class="span" @click="privacy">《用户隐私政策》</div>
          <div class="span" @click="service">《服务协议》</div>
        </div>
      </div>
    </div>
    <div class="vant">
      <van-dialog v-model:show="privacyVisible" show-cancel-button @confirm="confirm">
        <div class="panel">
          <Privacy/>
        </div>
      </van-dialog>
      <van-dialog v-model:show="serviceVisible" show-cancel-button @confirm="confirm">
        <div class="panel">
          <Service/>
        </div>
      </van-dialog>
      <van-dialog v-model:show="iframeVisible" class="myIframePaneldialog" show-cancel-button @confirm="confirm">
        <div class="myIframePanel" id="scrollableDiv">
          <div class="myIframePanel1">
            <iframe
                id="myIframe" ref="myIframe" width="100%" height="100%"
                src="https://unipayphone.wostore.cn/sitepay/member_pay.html?torder=23e2c71b274645e89158eebc48ef589b&sign=41b4fd3fe3424c0ffaa31b7b571aa9f7"></iframe>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
  1
</template>

<script>

import {Toast} from 'vant';
import {push, subscribe} from "@/api";
import Privacy from "@/views/privacy.vue";
import Service from "@/views/service.vue";
import {px2Rem, px2Rem1} from "@/utils/rem";
import {decode} from "js-base64";

export default {
  name: "index",
  components: {Service, Privacy},
  data() {
    return {
      input: "",
      option: false,
      privacyVisible: false,
      serviceVisible: false,
      iframeVisible: false,
      query: {},
    }
  },
  created() {


    push({
      type: 0,
    })
    this.query = this.$route.query
    if (this.$route.query?.phone) {
      this.query.phone = decode(decodeURIComponent(this.$route.query.phone))
      let regex = /^1[3456789]\d{9}$/;

      if (regex.test(this.query.phone)) {
        this.input = this.query.phone
      } else {
        this.query.phone = ""
      }
    }
  },
  methods: {
    btnClick1() {
      this.iframeVisible = true

      this.setupIframe()
      const time = setInterval(() => {
        const iframe = this.$refs.myIframe;
        if (iframe) {
          this.setupIframe()
          clearInterval(time)
        }
      }, 100)

    },
    setupIframe() {
      let num = px2Rem1(16)
      const iframe = this.$refs.myIframe;
      if (!iframe) {
        return;
      }
      iframe.onload = () => {
        let scrollableDiv = document.getElementById('scrollableDiv');
        scrollableDiv.scrollTop = px2Rem1()
        scrollableDiv.style.overflow = "hidden"
      };
    },
    btnClick() {
      this.option = true
      push({
        type: 1,
      })
      if (this.option) {
        let regex = /^1[3456789]\d{9}$/;

        // 测试输入的手机号是否符合格式
        if (regex.test(this.input)) {
          let toast = Toast.loading({
            duration: 0, // 持续展示 toast
            message: '加载中...',
            forbidClick: true,
          });
          subscribe({
            userCode: this.input,
            channelId: this.query.channelId ? this.query.channelId : null,
            phone: this.query.phone,
            parameter: this.query.a_oId ? JSON.stringify({a_oId: this.query.a_oId}) : null,
            url: "https://testcdn2.hangzhouluofeng.com/#/"
          }).then(res => {
            if (res.success) {
              toast.clear()
              window.location.href = res.data.url
            } else {
              Toast(res.message)
            }
          })
        } else {
          Toast('手机号格式错误')
        }
      } else {
        Toast('请阅读并同意《用户隐私政策》')
      }
    },
    privacy() {
      this.privacyVisible = true
    },
    service() {
      this.serviceVisible = true
    },
    confirm() {
      this.option = true
    },
    focus() {
      push({
        type: 2,
      })
    }
  }
};
</script>

<style lang="less">
body {
  .van-toast {
    width: 12rem;
    text-align: center;
    //background-color: #01d0c0;
  }

  .van-popup--center {
    top: 40%;
  }

  .van-toast--text {
    font-size: .8rem;
  }

  .panel {
    height: 500px;
    overflow-y: auto;

  }

  .myIframePaneldialog {
    .van-dialog__footer {
      display: none;
    }
  }

  .myIframePanel {
    height: 16rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;


    .myIframePanel1 {
      height: 800px;
      width: 100%;
    }
  }

  .myIframePanel::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  #uniauthframe {
    body {
      overflow: hidden;
    }
  }

  .van-dialog {
    width: 20rem;
  }

  .van-dialog__cancel {
    height: 2rem;
  }

  .van-dialog__confirm {
    height: 2rem;
  }

  .vant {
    .van-popup--center {
      top: 50%;
    }
  }
}

@keyFrames mymove {
  0% {
    transform: scale(.9, .9);
  }
  25% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

@-webkit-keyframes mymove {
  0% {
    transform: scale(.9, .9);
  }
  25% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.9, .9);
  }
}

.body {
  margin: 0 auto;
  padding: 0;
  position: relative;
  max-width: 750px;
  //padding: 0 1rem
  .detail {
    width: 100%;
    position: absolute;
    top: 24.47rem;
    text-align: center;
  }

  .input {
    width: 12.93rem;
    height: 2.42rem;
    margin: 0 auto;

    input {
      border: none;
      border-radius: 1.87rem;
      padding: .75rem 2rem;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      text-align: center;
    }

    input::placeholder {
      text-align: center;
      font-size: .93rem;
    }
  }

  .btn {

    width: 13.05rem;
    height: 2.45rem;
    margin: 2.56rem auto 0;
    line-height: 2.45rem;
    text-align: center;
    position: relative;
    z-index: 1;
    animation: mymove .7s infinite;
    -webkit-animation: mymove .7s infinite;

    .text {

      font-size: 1.44rem;
      //font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #FFFFFF;
      border-radius: 1.22rem;
      border: 0.09px solid #F8E5B8;
      background: linear-gradient(0deg, #E22714, #FFE7DD);

    }

    .position {
      position: absolute;
      right: 0;
      top: -1rem;
      width: 4.5rem;
      height: 1.63rem;
      z-index: -1;
      line-height: 1.1rem;
      box-sizing: border-box;
      background: linear-gradient(-90deg, #25A0FF, #01D0C0);
      font-size: 0.81rem;
      text-align: center;
      font-weight: normal;
      color: #FFFFFF;
      clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 200%);
    }

  }

  .checkbox {
    margin-top: 1.38rem;
    height: 1rem;
    line-height: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: inline-block;
      width: 0.69rem;
      height: 0.69rem;
      //width: 0.69rem;
      //height: 0.69rem;

      //border: 1px solid #E54243;
    }

    div {
      //width: 5.91rem;
      //height: 1rem;
      line-height: 1rem;
      font-size: 0.7rem;
      color: #3F3F3F;
      margin-left: 2px;
      display: inline-block;

      .span {
        color: #fff;
      }
    }
  }


}

.amap-logo,
.amap-copyright {
  display: none !important;
}

.amap-logo {
  display: none !important;
}

.amap-copyright {
  opacity: 0;
}
</style>
